// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"0C2Gz5F9F9tmOJIME7X2_"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';

Sentry.init({
   enabled: getClientPublicRuntimeConfig().sentryEnv !== 'development',
   dsn:
      getClientPublicRuntimeConfig().sentryDsn ??
      'https://a4e528eaecd54c96b5d67dd7f19495a7@o4503938252275712.ingest.sentry.io/4503938260598784',
   // Adjust this value in production, or use tracesSampler for greater control
   tracesSampleRate: 0.25,
   environment: getClientPublicRuntimeConfig().sentryEnv,

   // This sets the sample rate to be 10%. You may want this to be 100% while
   // in development and sample at a lower rate in production
   replaysSessionSampleRate: 0.1,

   // If the entire session is not sampled, use the below sample rate to sample
   // sessions when an error occurs.
   replaysOnErrorSampleRate: 1.0,
   integrations: [
      Sentry.replayIntegration({
         // Additional SDK configuration goes in here, for example:
         maskAllText: true,
         blockAllMedia: true,
      }),
   ],
   beforeSend(event) {
      // Modify the event here
      if (event.user) {
         // Don't send user's ip address
         delete event.user.ip_address;
      }
      return event;
   },

   tracesSampler: ({ name, parentSampled }) => {
      // Do not sample health checks ever
      if (name.includes('healthz')) {
         return 0;
      }

      // These are important - take a big sample
      const importantErrorNames = [
         'payment',
         'checkout',
         'purchase',
         'auth',
         'cart',
      ];

      if (importantErrorNames.some((endpoint) => name.includes(endpoint))) {
         return 1;
      }

      // Continue trace decision, if there is any parentSampled information
      if (typeof parentSampled === 'boolean') {
         return parentSampled;
      }

      // Else, use default sample rate
      return 0.3;
   },

   ignoreErrors: [
      // Sendbird error, https://innerwell.sentry.io/share/issue/c066079f6a01486388bbb51aba90e7c0/
      'Connection is closed. Please reconnect.',
      // No need to report this error & Network error (due to user connection issues)
      'Request aborted',
      /^Network Error$/,
      // Hide hydration errors due to browser extensions
      /^Hydration Error$/,
   ],
   denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
   ],
});
